APP.cursor = function () {
  const el = document.querySelector('.cursor')
  const bounds = el.getBoundingClientRect()
  const renderedStyles = {
    tx: { previous: 0, current: 0, amt: 0.2 },
    ty: { previous: 0, current: 0, amt: 0.2 },
    scale: { previous: 1, current: 1, amt: 0.15 },
    opacity: { previous: 1, current: 1, amt: 0.1 },
  }
  let mouse = { x: 0, y: 0 }

  const init = function () {
    gsap.set(el, { alpha: 0 })
    window.addEventListener('mousemove', function (e) {
      mouse = getMousePos(e)
    })
    window.addEventListener('mousemove', onMouseMoveEv)
    ;[
      ...document.querySelectorAll('a, button, .form__control, .radio, .checkbox, .collapse__head'),
    ].map((link) => {
      link.addEventListener('mouseenter', enter)
      link.addEventListener('mouseleave', leave)
    })
  }

  const onMouseMoveEv = function () {
    renderedStyles.tx.previous = renderedStyles.tx.current = mouse.x - bounds.width / 2
    renderedStyles.ty.previous = renderedStyles.ty.previous = mouse.y - bounds.height / 2
    gsap.to(el, { duration: 0.9, ease: 'Power3.easeOut', opacity: 1 })
    requestAnimationFrame(render)
    window.removeEventListener('mousemove', onMouseMoveEv)
  }

  const enter = function () {
    renderedStyles['scale'].current = 3
    renderedStyles['opacity'].current = 0.5
  }

  const leave = function () {
    renderedStyles['scale'].current = 1
    renderedStyles['opacity'].current = 1
  }

  const render = function () {
    renderedStyles['tx'].current = mouse.x - bounds.width / 2
    renderedStyles['ty'].current = mouse.y - bounds.height / 2

    for (const key in renderedStyles) {
      renderedStyles[key].previous = lerp(
        renderedStyles[key].previous,
        renderedStyles[key].current,
        renderedStyles[key].amt
      )
    }

    gsap.set(el, {
      alpha: renderedStyles['opacity'].previous,
      x: renderedStyles['tx'].previous,
      y: renderedStyles['ty'].previous,
      scale: renderedStyles['scale'].previous,
      force3D: true,
    })

    requestAnimationFrame(render)
  }

  const lerp = function (a, b, n) {
    return (1 - n) * a + n * b
  }

  const getMousePos = function (e) {
    return {
      x: e.clientX,
      y: e.clientY,
    }
  }

  init()
}
