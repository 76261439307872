APP.headerScroll = function () {
  const header = $('.header--scroll')
  const navbar = header.find('.navbar')
  const navbarItem = navbar.find('.navbar__item')
  const navbarLink = navbar.find('.navbar__link')
  const scene = $('.scene')
  const scrollRegister = $('.scroll--register, .sample__btn')
  let isClickMenu = false
  let posY = WIN.scrollTop()

  const init = function () {
    onScroll()
    navbarLink.on('click', onClickNavbarLink)
    scrollRegister.on('click', onClickScrollRegister)
    WIN.on('scroll', onScroll)
  }

  const onClickNavbarLink = function (evt) {
    evt.preventDefault()
    const _this = $(this)
    const sceneId = $(_this.attr('href'))
    const sceneIdPosY = sceneId.offset().top
    isClickMenu = true
    APP.setScrollTopAnimation(sceneIdPosY, {
      duration: 0.7,
      onFinish() {
        isClickMenu = false
      },
    })
  }

  const onClickScrollRegister = function (evt) {
    evt.preventDefault()
    const _this = $(this)
    const sceneId = $(_this.attr('href'))
    const sceneIdPosY = sceneId.offset().top
    APP.setScrollTopAnimation(sceneIdPosY, {
      duration: 0.7,
    })
  }

  const onScroll = function () {
    if (!isClickMenu) {
      posY = WIN.scrollTop()

      scene.each(function () {
        const _this = $(this)
        let top = _this.offset().top - 10
        let bottom = top + _this.outerHeight(true)

        if (posY >= top && posY <= bottom) {
          navbarItem.removeClass(CLASS._active)
          scene.removeClass(CLASS._active)
          _this.addClass(CLASS._active)
          navbarItem
            .find('a[href="#' + _this.attr('id') + '"]')
            .parent()
            .addClass(CLASS._active)
          if (_this.attr('id') === 'scene-3' || _this.attr('id') === 'scene-4') {
            gsap.to('.sample', {
              alpha: 0,
              x: '100%',
              duration: 0.4,
              ease: Power1.easeOut,
            })
          } else {
            gsap.to('.sample', {
              alpha: 1,
              x: '0%',
              duration: 0.4,
              ease: Power1.easeOut,
            })
          }
        }
      })
    }
  }

  init()
}
