document.addEventListener("DOMContentLoaded", function () {
    function slide() {
        new TimelineMax().to("#item".concat(i), 1, { x: "-50%", z: 0, force3D: !1 }, 0).to("#item".concat(o), 1, { x: "110%", z: -800, force3D: !1 }, 0).to("#item".concat(a), 1, { x: "-110%", z: -800, force3D: !1 }, 0);
        $(".bobby-carousel-item").removeClass("active")
        $("#item".concat(i)).addClass("active");
        $('.carousel-indicators li').removeClass('active')
        $('li[data-slide-to="' + (i) + '"]').addClass('active')
    }
    function left() {
        var e = i;
        (i = o), (o = a), (a = e), slide();
    }
    function right() {
        var e = i;
        (i = a), (a = o), (o = e), slide();
    }

    function colapseS() {
        var e = $("#item1").height() + 280 + "px";
        $(".bobby-carousel-list").css("min-height", e)
        $(".bobby-carousel-item:not(#item1)").css("min-height", e);
    }

    var n, i, o, a;

    (i = 1),
        (o = 2),
        (a = 3),
        $(".bobby-carousel-arrow.arrow-next").on("click", right),
        $(".bobby-carousel-arrow.arrow-prev").on("click", left),
        jQuery(".bobby-carousel-list").on("swipeleft", left),
        jQuery(".bobby-carousel-list").on("swiperight", right),
        $('.js-go-to-page[href="#loiNenThanKy"], .js-switcher').on("click", function () {
            (i = 1), (o = 2), (a = 3), slide();
        }),
        $(function () {
            slide();
            var i = $(".button-get-sample"), o = $(".js-switcher"), a = $("[data-page-id=".concat(4, "]"))
            $(document).on("scroll", function () {
                $(document).scrollTop() > a.offset().top - a.innerHeight() + $(".bobby-shop").innerHeight() / 2 ? (o.addClass("hidden"), i.addClass("hidden")) : (o.removeClass("hidden"), i.removeClass("hidden"))
                $(document).scrollTop() > 400 ? (o.addClass("hidden"), i.addClass("hidden")) : (o.removeClass("hidden"), i.removeClass("hidden"))
            })
        })
    //setTimeout(colapseS(), 1000)
})

$("body").on("click", ".bobby-carousel-item.active .accordion-title", function (e) {
    e.preventDefault();
    var t = $(this),
        s = t.parent().find(".accordion-body"),
        e = t.parents(".accordion-block");
    t.hasClass("active")
        ? (t.removeClass("active"), s.removeClass("displayed"))
        : (e.find(".accordion-title.active").removeClass("active"), e.find(".accordion-body.displayed").removeClass("displayed"), t.addClass("active"), s.addClass("displayed"));
    //colapseS();
});
