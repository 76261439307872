APP.header = function () {
  const navbar = $('.navbar')
  const navbarItem = navbar.find('.navbar__item')

  const init = function () {
    navbarItem.on('click', onClickNavbarItem)

    const id = localStorage.getItem('scene_id') ? localStorage.getItem('scene_id') : ''

    if (id) {
      setTimeout(function () {
        const sceneId = $(id)
        const sceneIdPosY = sceneId.offset().top
        APP.setScrollTopAnimation(sceneIdPosY, {
          duration: 0.7,
        })
        localStorage.removeItem('scene_id')
      }, 300)
    }

    $('.navbar__item-shop .navbar__link').on('click', function (evt) {
      const _this = $(this)
      const id = _this.data('id')
      localStorage.setItem('scene_id', id)
    })
  }

  const onClickNavbarItem = function () {
    const _this = $(this)
    navbarItem.removeClass(CLASS._active)
    _this.addClass(CLASS._active)
  }

  init()
}
