APP.home = function () {
  AOS.init({
    duration: 900,
  })

  $('.slick--product').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })

  $('select').selectric()

  APP.collapse('.collapse', {
    isOpen: true,
  })

  APP.video()
  APP.validator()

  $('.form__rule').on('click', function (evt) {
    evt.preventDefault()
    APP.modal('.modal--rule')
  })

  $('.sizeta').on('click', function(event){
    event.preventDefault()
    APP.modal('.modal--size')
  })
}
