APP.init = function () {
  APP.device()

  APP.preloader({
    onComplete() {},
  })

  APP.header()
  APP.headerScroll()
  APP.home()
}
