APP.isMobile = function () {
  if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i))
    return true
  else return false
}

APP.isTablet = function () {
  if (navigator.userAgent.match(/Tablet|iPad/i)) return true
  else return false
}

APP.isDesktop = function () {
  if (!APP.isMobile() && !APP.isTablet()) return true
  else return false
}

APP.isLandscape = function () {
  if (window.innerHeight < window.innerWidth) return true
  else return false
}

APP.resize = function (func, timer) {
  let resizeEnd
  timer = timer !== undefined ? timer : 150
  window.addEventListener('resize', function () {
    clearTimeout(resizeEnd)
    resizeEnd = setTimeout(func, timer)
  })
  return true
}

APP.setScrollTopAnimation = function (posY, options) {
  const settings = $.extend(
    {
      duration: 0.4,
      onFinish() {},
    },
    options
  )

  gsap.to(window, {
    scrollTo: {
      y: posY,
      autoKill: false,
    },
    duration: settings.duration,
    ease: Power0.easeOut,
    onComplete: function () {
      settings.onFinish()
    },
  })
  return true
}

APP.getScrollTop = function () {
  return window.scrollY
}

APP.setCookie = function (key, value, days) {
  const expires = new Date()
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = key + '=' + value + ';path=/' + ';expires=' + expires.toUTCString()
  return true
}

APP.getCookie = function (key) {
  const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)')
  return keyValue ? keyValue[2] : null
}

APP.removeCookie = function (key) {
  APP.setCookie(key, '', -1)
  return true
}

APP.getScrollbarWidth = function () {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  outer.style.msOverflowStyle = 'scrollbar'
  document.body.appendChild(outer)
  const inner = document.createElement('div')
  outer.appendChild(inner)
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
  outer.parentNode.removeChild(outer)
  return scrollbarWidth
}

APP.sortOn = function (arr, prop) {
  arr.sort(function (a, b) {
    return a[prop].localeCompare(b[prop])
  })
}
