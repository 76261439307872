APP.collapse = function (element, options) {
  const el = $(element)
  const collapse = el.find('.collapse__item')
  const collapseHead = collapse.find('.collapse__head')
  const collapseBody = collapse.find('.collapse__body')

  const settings = $.extend(
    {
      isOpen: false,
      onComplete: function () {},
    },
    options
  )

  const init = function () {
    setup()
    collapseHead.on('click', handleToggle)
  }

  const setup = function () {
    if (settings.isOpen) {
      setTimeout(function () {
        collapse.eq(0).addClass(CLASS._show)
        detect()
        APP.resize(detect)
      }, 1000)
    }
  }

  const handleToggle = function (evt) {
    evt.preventDefault()
    const obj = $(this)
    const collapseShow = obj.parents('.collapse__item')
    const collapseShowBody = collapseShow.find('.collapse__body')
    const collapseShowContent = collapseShow.find('.collapse__content')
    const collapseShowContentHeight = collapseShowContent.outerHeight(true)
    const collapseShowIndex = collapseShow.index()

    if (!collapseShow.hasClass(CLASS._show)) {
      collapseBody.removeClass(CLASS._show)
      collapse.removeClass(CLASS._show)

      gsap.to(collapseBody, {
        height: 0,
        duration: 0.4,
        ease: Power3.easeOut,
      })

      collapseShowBody.addClass(CLASS._show)
      collapseShow.addClass(CLASS._show)

      gsap.to(collapseShowBody, {
        height: collapseShowContentHeight,
        duration: 0.45,
        ease: Power3.easeOut,
      })

      showProduct(collapseShowIndex)
    }
  }

  const detect = function () {
    const collapseShow = el.find('.is-show')
    const collapseShowBody = collapseShow.find('.collapse__body')
    const collapseShowContent = collapseShow.find('.collapse__content')
    const collapseShowContentHeight = collapseShowContent.outerHeight(true)

    gsap.to(collapseShowBody, {
      height: collapseShowContentHeight,
      duration: 0.45,
      ease: Power3.easeOut,
    })
  }

  const showProduct = function (index) {
    const productMedia = $('.product-media')
    const productMediaItem = $('.product-media__item')
    const productMediaItemShow = $('.product-media__item').eq(index)

    gsap.to(productMediaItem, {
      alpha: 0,
      scale: 0.5,
      duration: 0.45,
      ease: Power3.easeOut,
      onComplete() {
        gsap.set(productMediaItem, {
          display: 'none',
        })

        gsap.set(productMediaItemShow, {
          alpha: 0,
          scale: 0.5,
          display: 'block',
        })

        gsap.to(productMediaItemShow, {
          alpha: 1,
          scale: 1,
          duration: 0.45,
          ease: Power3.easeOut,
        })
      },
    })
  }

  init()
}
