APP.video = function () {
  const video = $('.video')
  const videoModal = video.find('.video__modal')
  const videoBody = video.find('.video__body')
  const videoClose = video.find('.video__close')
  const videoBtnMobile = video.find('.video__btn-mobile')
  const videoTvc = document.querySelector('#video-tvc')
  if (video.length > 0) {
    videoTvc.addEventListener('ended', myHandler, false)
    videoBody.on('click', function (evt) {
      evt.preventDefault()
      const _this = $(this)
      _this.toggleClass(CLASS._played)
      if (_this.hasClass(CLASS._played)) {
        videoTvc.play()
        if (APP.isDesktop()) {
          videoModal.addClass(CLASS._show)
          videoBtnMobile.addClass(CLASS._hide)
        }
      } else {
        videoTvc.pause()
        if (APP.isDesktop()) {
          videoBtnMobile.removeClass(CLASS._hide)
          videoModal.removeClass(CLASS._show)
        }
      }
    })

    videoBtnMobile.on('click', function (evt) {
      evt.preventDefault()
      const _this = $(this)
      _this.addClass(CLASS._hide)
      videoModal.addClass(CLASS._show)
      videoBody.addClass(CLASS._played)
      videoTvc.currentTime = 0
      videoTvc.play()
    })

    videoClose.on('click', function (evt) {
      evt.preventDefault()
      videoModal.removeClass(CLASS._show)
      videoBody.removeClass(CLASS._played)
      videoBtnMobile.removeClass(CLASS._hide)
      videoTvc.pause()
    })
  }

  function myHandler(e) {

    if (!e) {
      e = window.event
    }
    video.removeClass(CLASS._played)
    videoModal.removeClass(CLASS._show)
    videoBtnMobile.removeClass(CLASS._hide)
    videoTvc.currentTime = 0
    videoTvc.load()
  }
}
