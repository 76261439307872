APP.alert = function (options) {
  let alert, alertDialog, alertMain, alertFoot, btnOk, btnConfirm

  const settings = $.extend(
    {
      message: '',
      isConfirm: false,
      onComplete: function () {},
    },
    options
  )

  const init = function () {
    setup()
    btnOk.on('click', handleOk)
  }

  const setup = function () {
    handleOk()

    alert = MAIN.append($('<div>').addClass('alert')).find('.alert')

    alertDialog = alert.append($('<div>').addClass('alert__dialog')).find('.alert__dialog')

    gsap.set(alertDialog, {
      x: -100,
      zIndex: 2,
      y: '-50%',
    })

    BODY.addClass(CLASS._noscroll)
    gsap.set(BODY, {
      paddingRight: APP.getScrollbarWidth(),
    })

    gsap.set('.header', {
      paddingRight: APP.getScrollbarWidth(),
    })

    alertMain = alertDialog.append($('<div>').addClass('alert__main')).find('.alert__main')

    alertMain.append($('<div>').addClass('alert__body').html(settings.message)).find('.alert__body')

    alertFoot = alertMain.append($('<div>').addClass('alert__foot')).find('.alert__foot')

    if (settings.isConfirm) {
      btnConfirm = alertFoot
        .append(
          $('<button>').addClass('btn btn--ok').html('<span>Ok</span>').attr('type', 'button')
        )
        .find('.btn--ok')

      btnConfirm.append('<i class="icon icon--arrow-alt-right">')

      btnOk = alertFoot
        .append(
          $('<button>')
            .addClass('btn btn--cancel')
            .html('<span>Cancel</span>')
            .attr('type', 'button')
        )
        .find('.btn--cancel')

      btnConfirm.on('click', handleConfirm)
    } else {
      btnOk = alertFoot
        .append(
          $('<button>').addClass('btn btn--ok').html('<span>Ok</span>').attr('type', 'button')
        )
        .find('.btn--ok')

      btnOk.append('<i class="icon icon--arrow-alt-right">')
    }

    gsap.to(alertDialog, {
      x: '-50%',
      duration: 0.7,
      ease: Elastic.easeOut,
    })
  }

  const handleOk = function () {
    $('.alert').remove()
    BODY.removeClass(CLASS._noscroll).removeAttr('style')
    $('.header').removeAttr('style')
  }

  const handleConfirm = function () {
    settings.onComplete()
    handleOk()
  }

  init()
}
