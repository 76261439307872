APP.validator = function () {
  const formRegister = $('.form--register')
  const formGroup = formRegister.find('.form__group')
  const btnRegister = formRegister.find('.btn--register')
  const selectCity = formGroup.find('[name="city"]')
  const selectDistrict = formGroup.find('[name="district"]')
  const selectCommune = formGroup.find('[name="ward"]')

  const init = function () {
    if (formRegister.length > 0) {
      iMaskCheck()
      appendCities()
      appendDistricts()
      appendWards()
      changeSelect()
      btnRegister.on('click', onSubmit)
    }
  }

  const iMaskCheck = function () {
    IMask(document.querySelector('[name="phone"]'), {
      mask: /^[0-9,+]\d{0,12}$/,
    })

    IMask(document.querySelector('[name="month"]'), {
      mask: /^\d{0,2}$/,
    })

    IMask(document.querySelector('[name="weight"]'), {
      mask: /^\d{0,2}$/,
    })

    $('[name="fullname"]').on('keypress', function (e) {
      let key = e.keyCode
      console.log(key)
      if (
        (key >= 42 && key <= 63) ||
        key === 33 ||
        key === 34 ||
        key === 39 ||
        key === 95 ||
        key === 96 ||
        (key >= 91 && key <= 93) ||
        (key >= 123 && key <= 126)
      )
        return false
    })
  }

  const onSubmit = function (evt) {
    evt.preventDefault()
    let isFlag = true
    formGroup.each(function () {
      const _this = $(this)
      const input = _this.find('.form__control')
      const type = input.attr('type')
      const value = input.val()

      if (value === '' || ((type === 'radio' || type === 'checkbox') && !input.is(':checked'))) {
        APP.alert({
          message: '<p>Bạn vui lòng nhập đầy đủ thông tin</p>',
        })
        isFlag = false
      } else {
        switch (type) {
          case 'email':
            if (!checkEmail(value)) {
              APP.alert({
                message: '<p>Email không đúng định dạng</p>',
              })
              isFlag = false
            }
            break
          default:
            break
        }
      }
    })

    if (isFlag) {
      let data = {}
      const users = formRegister.serializeArray()
      const date = moment().format('HH:mm:ss DD/MM/YYYY')
      users.forEach(function (e, i) {
        if (e.name !== 'agree') data = { ...data, [e.name]: e.value, date }
      })
      $('.loading').show()
      fetch('/ta-dan', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          $('.loading').hide()
          if (response.status === 400) {
            APP.alert({
              message: '<p>Thông tin đã tồn tại trên hệ thống của Bobby!</p>',
            })
          } else if (response.status === 201) {
            resetForm()

            if($('.modal--register').length)
              APP.modal('.modal--register')
            else
              APP.alert({
                message: '<p>Chúc mừng bạn đã đăng ký thành công</p>',
              })
          } else {
            APP.alert({
              message: '<p>Lỗi không xác định, vui lòng thử lại sau!</p>',
            })
          }
        })
        .catch((err) => {
          $('.loading').hide()
          APP.alert({
            message: `<p>${err.message}</p>`,
          })
        })
    }
  }

  const checkEmail = function (email) {
    const reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(String(email).toLowerCase())
  }

  const appendCities = function () {
    fetch('data/cities.json')
      .then((res) => res.json())
      .then((cities) => {
        let options = `<option value="">Thành phố</option>`
        APP.sortOn(cities, 'name')
        cities
          .map((city) => {
            options += `<option data-city-id="${city.idCity}" value="${city.name}">${city.name}</option>`
          })
          .join('')
        selectCity.html(options)
        selectCity.selectric('destroy')
        selectCity.selectric()
      })
      .catch((err) => console.log(err))
  }

  const appendDistricts = function () {
    selectCity.on('change', function (evt) {
      const selectedCity = $(this).find('option:selected')
      let idCity = selectedCity.data('city-id') ? selectedCity.data('city-id').toString() : null
      let options = `<option value="">Xã/Phường</option>`
      $('.ajax--district').show()
      fetch('data/districts.json')
        .then((res) => res.json())
        .then((districts) => {
          let options = `<option value="">Quận/Huyện</option>`
          APP.sortOn(districts, 'name')
          districts
            .map((district) => {
              if (district.idCity.toString() === idCity)
                options += `<option data-district-id="${district.idDistrict}" value="${district.name}">${district.name}</option>`
            })
            .join('')
          selectDistrict.html(options)
          selectDistrict.selectric('destroy')
          selectDistrict.selectric()
          $('.ajax--district').hide()
        })
        .catch((err) => console.log(err))
      selectCommune.html(options)
      selectCommune.selectric('destroy')
      selectCommune.selectric()
    })
  }

  const appendWards = function () {
    selectDistrict.on('change', function (evt) {
      const selectedDistrict = $(this).find('option:selected')
      let idDistrict = selectedDistrict.data('district-id')
        ? selectedDistrict.data('district-id').toString()
        : null
      $('.ajax--ward').show()
      fetch('data/wards.json')
        .then((res) => res.json())
        .then((wards) => {
          let options = `<option value="">Xã/Phường</option>`
          APP.sortOn(wards, 'name')
          wards
            .map((ward) => {
              if (ward.idDistrict.toString() === idDistrict)
                options += `<option data-district-id="${ward.idWard}" value="${ward.name}">${ward.name}</option>`
            })
            .join('')
          selectCommune.html(options)
          selectCommune.selectric('destroy')
          selectCommune.selectric()
          $('.ajax--ward').hide()
        })
        .catch((err) => console.log(err))
    })
  }

  const resetForm = function () {
    formRegister[0].reset()
    $('select').selectric()
  }

  const changeSelect = function () {
    $('select').on('change', function () {
      const select = $(this)
      const parent = select.parents('.selectric')
      const label = parent.find('.selectric__label')
      const value = select.val()
      if (value !== '') {
        label.addClass(CLASS._valid)
      } else {
        label.removeClass(CLASS._valid)
      }
    })
  }

  init()
}
