const APP = {}

const DOC = $(document)
const WIN = $(window)
const BODY = $(document.body)
const MAIN = $('.main')

const CLASS = {
  _desktop: 'is-desktop',
  _tablet: 'is-tablet',
  _mobile: 'is-mobile',
  _portrait: 'is-portrait',
  _landscape: 'is-landscape',
  _active: 'is-active',
  _current: 'is-current',
  _valid: 'is-valid',
  _error: 'is-error',
  _focus: 'is-focus',
  _scroll: 'is-scroll',
  _noscroll: 'is-noscroll',
  _hover: 'is-hover',
  _show: 'is-show',
  _hide: 'is-hide',
  _played: 'is-played',
}

let dw = DOC.width()
let dh = DOC.height()
let ww = WIN.width()
let wh = WIN.height()