APP.preloader = function (options) {
  const preloader = $('.preloader')
  const settings = $.extend(
    {
      onComplete() {},
    },
    options
  )

  dh = DOC.height()
  wh = WIN.height()

  BODY.addClass(CLASS._noscroll)

  if (dh > wh) {
    gsap.set(BODY, {
      paddingRight: APP.getScrollbarWidth(),
    })
  }

  BODY.imagesLoaded({ background: true }, function () {
    gsap.to(preloader, {
      autoAlpha: 0,
      duration: 1,
      ease: 'linear',
      onComplete() {
        preloader.remove()
        BODY.removeAttr('style')
        BODY.removeClass(CLASS._noscroll)
        settings.onComplete()
      },
    })
  })
}
