APP.taber = function (element, options) {
  const el = $(element)
  const tabLink = el.find('.taber__link')
  const tabContent = el.find('.taber__content')

  const settings = $.extend(
    {
      isHash: false,
      onComplete: function () {},
    },
    options
  )

  const init = function () {
    detect()
    tabLink.on('click', handleLink)
  }

  const detect = function () {
    const hash = location.hash

    if (hash) {
      setTimeout(function () {
        $('.taber__link[href="' + hash + '"]').trigger('click')
      }, 100)
    } else {
      tabLink.eq(0).addClass(CLASS._active)
      tabContent.eq(0).addClass(CLASS._active)
    }
  }

  const handleLink = function (evt) {
    evt.preventDefault()
    const obj = $(this)
    const tabShow = obj.attr('href')
    const link = location.origin + '/' + tabShow

    tabLink.removeClass(CLASS._active)
    obj.addClass(CLASS._active)

    tabContent.removeClass(CLASS._active)
    $(tabShow).addClass(CLASS._active)

    gsap.set(tabContent, {
      alpha: 0,
    })

    gsap.to(tabShow, 0, {
      alpha: 1,
      ease: Power3.easeOut,
      onComplete: function () {
        settings.onComplete()
      },
    })

    if (settings.isHash) {
      location.href = link
    }
  }

  init()
}
