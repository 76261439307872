APP.modal = function (element, options) {
  const el = $(element)
  const navbar = $('.navbar')
  const modalDialog = el.find('.modal__dialog')
  const modalClose = modalDialog.find('.modal__close')
  let modalDialogHeight = modalDialog.innerHeight()
  let scrollbarWidth = APP.getScrollbarWidth()

  const settings = $.extend(
    {
      isOverlay: true,
      onComplete: function () {},
    },
    options
  )

  function init() {
    detectModal()
    APP.resize(detectModal)
    animateModal()

    modalClose.on('click', hideModal)

    if (settings.isOverlay) {
      el.on('click', function (evt) {
        const obj = $(evt.target)
        if (obj.closest('.modal__dialog').length === 0) {
          hideModal()
        }
      })
    }
  }

  function detectModal() {
    wh = WIN.height()
    modalDialogHeight = modalDialog.innerHeight()
    if (modalDialogHeight > wh) {
      el.addClass(CLASS._scroll)
    } else {
      el.removeClass(CLASS._scroll)
    }
  }

  function animateModal() {
    BODY.css({
      paddingRight: scrollbarWidth,
    }).addClass(CLASS._noscroll)

    navbar
      .css({
        paddingRight: scrollbarWidth,
      })
      .addClass(CLASS._noscroll)

    el.addClass(CLASS._show)
    gsap.set(modalDialog, {
      autoAlpha: 0,
      y: 100,
    })

    gsap.to(modalDialog, {
      autoAlpha: 1,
      y: 0,
      duration: 0.4,
      ease: Power1.easeOut,
    })
  }

  function hideModal() {
    gsap.to(modalDialog, {
      autoAlpha: 0,
      y: 100,
      duration: 0.4,
      ease: Power1.easeOut,
      onComplete: function () {
        BODY.removeAttr('style').removeClass(CLASS._noscroll)
        navbar.removeAttr('style').removeClass(CLASS._noscroll)
        el.removeClass(CLASS._show)
      },
    })
  }

  init()
}
